// Add import fonts HERE

@import 'sass-reset'

@import './utils/helpers.sass'

:root
    --color-dark: #1b1b1b
    --color-light: #ffffff
    --color-gray: #8d8d8d
    --color-gray-light: #dbdbdb
    --color-primary: #000000

html
    height: auto !important
    min-height: 100%
    overflow-x: hidden
    font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"

h1, h2, h3, h4
    p 
        margin: 0

button
    outline: 0

#root
    display: flex
    flex-direction: column
    min-height: calc(100vh) // RB: 112px more than footer height to avoid CLS (Cumulative Shift Layout) LightHouse

main 
    flex-grow: 1

a
    color: currentColor
    // &:hover
    //     color: var(--color-primary)

a.button
    color: currentColor

.button ~ .button
    margin-left: 12px

.is-discret
    color: var(--color-gray)

// .is-highlight
//     -webkit-text-fill-color: transparent
//     background: linear-gradient(89.07deg, var(--color-primary) -1.23%, var(--color-secondary) 100.02%)
//     -webkit-background-clip: text

.v-icon
    font-size: 2.5em
    display: inline-block
    vertical-align: middle

@include wf-mobile-landscape
    .carousel
        --page-size: 1 !important
        --item-gap: 0px !important

// @include wf-desktop-large
//     .w-container:not(.is-full)
//         max-width: 1170px

