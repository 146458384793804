// https://university.webflow.com/lesson/intro-to-breakpoints

@mixin wf-mobile-portrait()
    @media screen and (max-width: 479px)
        @content

@mixin wf-mobile-landscape()
    @media screen and (max-width: 767px)
        @content

@mixin wf-tablet()
    @media screen and (max-width: 991px)
        @content

@mixin wf-desktop()
    @media screen and (min-width: 992px)
        @content

@mixin wf-desktop-large()
    @media screen and (min-width: 1280px)
        @content
