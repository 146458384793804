.is-lazy-section
    transition: opacity .5s ease 0s, transform .5s ease 0s
    opacity: 0
    transform: translateY(100px)
    will-change: transform, opacity
    &.is-lazy-section-reveal
        opacity: 1
        transform: translateY(0)
    &.is-lazy-section-hidden
        *
            background-image: unset !important
            background-color: white !important
            color: black !important